<template>
  <div class="hotls">
            <div class="ls_item flex" v-for="(item,i) in list" :key="i" @click.stop="tourl('/lvdetails?id=' + item.id)">
              <img :src="item.photo ? item.photo : '@/assets/img/ls.png'" alt="" class="lsimg">
              <div class="ls_right">
                <p class="title">{{item.lawyerName}}律师</p>
                <p class="title1">擅长领域：</p>
                <p class="title2"><span v-for="(data,index) in item.goodsIdsArr" :key="index">{{data}}</span></p>
                <div class="btnbox" @click.stop="tourl('/wenls?lawid=' + item.id)">向他咨询</div>
              </div>
            </div>
          </div>
</template>

<script>
export default {
  name: '',
  components: {},
  props: {
    // type: {
    //   type: Number,
    //   default: 1
    // },
    list: {
      type: Array,
      default: (()=>[])
    }
  },
  data() {
    return {
     
    }
  },

  mounted() {
   
  },

  methods: {
    tourl(url){
      this.$router.push({
        path:url
      })
    }
  }
}
</script>

<style lang="scss">

.hotls{
        width: 586px;
        background: #F8F8F8;
        border-radius: 12px;
        padding: 0px 32px;
       
        .ls_item{
          border-bottom: 1px solid  #E6E6E6;
          padding: 24px 0;
          cursor: pointer;
          .lsimg{
            width: 124px;
            height: 164px;
            border-radius: 16px;
            margin-right: 20px;
          }
          .ls_right{
            .title{
              font-size: 20px;
              font-weight: 500;
              color: #333333;
            }
            .title1{
              font-size: 16px;
              color: #333333;
              margin-top: 16px;
            }
            .title2{
              font-size: 16px;
              color: #666666;
              margin-top: 7px;
            }
            .btnbox{
              width: 104px;
              height: 36px;
              line-height: 36px;
              border-radius: 18px;
              font-size: 16px;
              color: #fff;
              margin-top: 32px;
            }
          }
        }
        .ls_item:nth-last-child(1){
          border-bottom: 1px solid transparent;
        }

      }
</style>