<template>

    <div class="rmzxbox" > 
      <div class="zxitem flexbox" v-for="(item,i) in list" :key="i" @click.stop="tourl('/infodetails?id=' + item.id)">
        <img :src="item.photo" alt="" class="leftimg">
        <div class="item_right flex_column_between">
          <p class="line1 ptitle">{{item.title}}</p>
          <div class="item_bottom flex_between">
            <span>{{item.createTime}}</span>
            <div class="imgbox flexbox" v-if="type == 1">
              <p @click.stop="toggleStatus(1,i,item.id)">
                <img src="@/assets/img/zan.png" alt="" v-if="item.islike == 0" >
                <img src="@/assets/img/zan1.png" alt="" v-if="item.islike == 1" >  
              </p>
              <p @click.stop="toggleStatus(2,i,item.id)">
                <img src="@/assets/img/xing.png" alt="" v-if="item.iscollect == 0" >
                <img src="@/assets/img/xing1.png" alt="" v-if="item.iscollect == 1" >
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import apiUrl from '@/api/main'
export default {
  name: '',
  components: {},
  props: {
  /**
   * type == 1  有点赞和收藏
   * type == 2  没有点赞和收藏
   */  
    type: {
      type: Number,
      default: 1
    },
  /**
   * widthType == 2  父元素 总宽 488时
   * 
   */  
    widthType: {
      type: Number,
      default: 1
    },
    list: {
      type: Array,
      default: (()=>[])
    }
  },
  data() {
    return {
      // ObjClass:{
      //   // rightwidth: this.widthType == 2
      // }
    }
  },

  mounted() {
   
  },

  methods: {
     // 跳转
     tourl(url) {
        this.$router.push({
          path: url
        })
      },
      toggleStatus(type, index, id) {
				let url = '';
				if (!localStorage.getItem('userId')) return;
				if (type == 1) {
					//点赞
					url = apiUrl.likeinformation;
				} else {
					// 收藏
					url = apiUrl.collectinformation;
				}
        let data = {
          id: id,
					userId: localStorage.getItem('userId')
        }
        url(data).then(res => {
          this.$message({
            message:res.msg,
            type:'none'
          })
					if (res.code == 0) {
						if (type == 1) {
							this.list[index].islike = this.list[index].islike == 0 ? 1 : 0;
						} else {
							this.list[index].iscollect = this.list[index].iscollect == 0 ?1 : 0;
						}
					}
				})
			},
  }
}
</script>

<style lang="scss">
 .rmzxbox{
      display: flex;
      flex-wrap: wrap;
      .zxitem{
        width: 403px;
        height: 70px;
        margin-bottom: 24px;
        margin-right: 24px;
        cursor: pointer;
        .leftimg{
          width: 124px;
          height: 70px;
          border-radius: 15px;
          margin-right: 12px;
        }
        .item_right{
          width: 67%;
          height: 70px;
          .ptitle{
            // width: 50%;
            font-size: 17px;
            font-weight: 500;
            color: #333333;
            &:hover{
              color: #305BFE;
            }
          }
          .item_bottom{
            // width: 50%;
            span{
              font-size: 14px;
              color: #777777;
            }
            .imgbox{
              img{
                width:16px;
                height: 16px;
              }
              img:nth-child(1){
                margin-right: 12px;
              }
            }
          }
        }
      }
      .zxitem:nth-child(2n){
        margin-right: 0px;
      }
    }

    // 右边 总宽 488时
    // .rightwidth{
    //   .ptitle{
    //     width: 268px;
    //   }
    // }

</style>