<template>
  <!-- 相关知识标签 问法 -->
  <div class="wenfa">
    <div class="common_title_box">
      <span class="line"></span>
      <span class="title">{{title}}</span>
    </div>
    <div class="artbox" v-for="(item,i) in list" :key="i" @click.stop="tourl('/askdetails?pId=' + item.pId + '&parentName=' + item.parentName + '&id=' + item.id)">
      <p class="arttitle">{{item.name}}</p>
      <div class="aetdetail line2">{{item.details | filtersText}}</div>
      <div class="seebox">
        <img src="@/assets/img/kan.png" alt="" />
        <span>{{item.browses}}次</span>
        <img src="@/assets/img/zan.png" alt="" v-if="item.islike == 0" @click.stop="togglelike(item,i)"/>
        <img src="@/assets/img/zan1.png" alt="" v-else @click.stop="togglelike(item,i)"/>
        <span>{{item.likeNum}}</span>
      </div>
    </div>

     <!-- 更多问题 -->
    <div class="morewen flexbox" v-if="more" @click.stop="tourl">更多
      <img src="@/assets/img/right.png" alt="">
    </div>
  </div>
</template>

<script>
import apiUrl from '@/api/main'
export default {
  name: 'index',
  components: {},
  props: {
    more: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    list: {
      type: Array,
      default: (()=>[])
    },
  },
  filters:{
    // 富文本转文本
    filtersText(val){
        if (val != null && val != '') {
            let reg = /[\u4e00-\u9fa5]/g;
            let names = val.match(reg);
            val = names ? names.join('') : '';
            return val;
        } else return '';
    },
},
  data() {
    return {
    }
  },

  mounted() {},

  methods: {
     // 跳转
     tourl(url) {
        // this.$router.push({
        //   path: url
        // })
        if(window.location.hash.indexOf(url) > -1){
          return;
        }else if(window.location.hash.indexOf('/askdetails?pId') > -1){
          this.$router.push({
            path: url
          })
          this.$emit('refresh');
        }else {
          this.$router.push({
            path: url
          })
        }
      },
      // 接收数据
      open(data){
        console.log(data);
        // this.title = data.name;
      },
    //切换点赞
    togglelike(item,i){
      if(!localStorage.getItem('userId')){
        return
      }
        let data = {
         		id: item.id,
				    userId: localStorage.getItem('userId')
        }
        apiUrl.likelsLawKnowledge(data).then((res) => {
          this.$message({
            message:res.msg,
            type:'none'
          });
          this.list[i].islike =  this.list[i].islike == 0 ? 1 : 0;
          this.list[i].likeNum =  this.list[i].islike == 0 ? parseFloat(this.list[i].likeNum) -1  : parseFloat(this.list[i].likeNum) + 1;
        })
			},
  }
}
</script>

<style lang="scss">
.wenfa {
  .common_title_box {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 27px;
  }
  .line {
    display: inline-block;
    width: 8px;
    height: 20px;
    background: #305bfe;
    border-radius: 3px;
    margin-right: 16px;
  }
  .artbox {
    border-bottom: 1px solid #eeeeee;
    padding-bottom: 20px;
    margin-bottom: 20px;
    cursor: pointer;
    .arttitle {
      font-size: 20px;
      font-weight: 500;
      color: #333333;
      margin-bottom: 10px;
    }
    .aetdetail {
      font-size: 16px;
      color: #666666;
      line-height: 23px;
      // text-indent: 32px;
      text-indent: 64px;
    }
    .seebox {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-top: 16px;
      img {
        width: 20px;
        height: 20px;
        margin-right: 7px;
        margin-left: 24px;
      }
      span {
        font-size: 14px;
        color: #666666;
        line-height: 17px;
      }
    }
  }

   // 更多问题
   .morewen{
    font-size: 13px;
    color: #777777;
    // margin-top: 30px;
    cursor: pointer;
    img{
      width: 18px;
      height: 18px;
      margin-left: 4px;
    }
  }
}
</style>